
import OrderTimeline from '@/components/order_timeline'
import LoadingSpinner from '@/components/loading_spinner'
import OrderShippingStatusPill from '@/components/order_shipping_status_pill'
import { number_to_currency, formatted_datetime } from '@/lib/helpers'
import { DateTime } from 'luxon'
import goTo from 'vuetify/lib/services/goto'
import ComboItems from '@/components/combo_items'

export default {
  name: 'OrderShow',
  components: { OrderTimeline, LoadingSpinner, ComboItems, OrderShippingStatusPill },

  data: -> {
    tab: 'receipt'
    remote_validation_modal: false,
    confirm_cancel_order_modal: false,
    loading_reorder: false,
    scroll_to_bottom_timeout: null
  }

  created: ->
    @$store.commit('cart/stop_order_proxy')
    @$store.commit('orders/polling', true)
    @$store.dispatch('orders/fetch', @$route.params.id)
    .then =>
      @build_tab_show(@order?.shipping_status)
      @$store.commit('layout/page_nav', {
        title: @$t('order_page_header', { reference: @order && @order.reference })
        back_target: '/orders'
      })

  beforeDestroy: ->
    clearTimeout(@scroll_to_bottom_timeout)

  destroyed: ->
    @$store.commit('orders/polling', false)
    @$store.commit('orders/timeout_id', clearTimeout(@$store.state.orders.timeout_id))
    @$store.commit('orders/flush_current') unless @$store.state.cart.proxy_order_id?

  computed: {
    debtorcard: ->
      {
        number: @$store.getters['account/find_debtorcard'](@supplier_id)?.debtor_card_number
        balance: @order.debtorcard_balance
      }

    collect_or_deliver: ->
      if @order?.requires_shipping then 'delivery' else 'collection'

    invalid_order_item: -> @$store.state.orders.invalid_order_item

    order: -> @$store.state.orders.current

    order_error: -> @$store.state.orders.error

    order_events: -> @order?.order_events

    supplier_id: -> @order?.supplier_id || null

    supplier_name: -> @order?.supplier_name || ''

    supplier_address: -> @order?.supplier_address || ''

    status: ->
      @order?.shipping_status || @order?.status || ''

    order_items: -> @$store.getters['orders/current_items']

    remote_validation: -> @$store.state.orders.remote_validation

    shipping_price_in_cents: -> if @order then @order.shipping_price_in_cents else 0

    stock_short: ->
      @$store.state.orders.current?.shipping_status == 'stock_short'

    pending_payment: ->
      @$store.state.orders.current?.shipping_status == 'pending_payment'

    subtotal_in_cents: ->
      @$store.getters['orders/subtotal_in_cents']

    total_in_cents: ->
      @$store.getters['orders/total_in_cents']

    payable_balance_in_cents: ->
      @order?.payable_balance_in_cents || 0

    loading: ->
      @$store.state.orders.loading

    is_reorderable: ->
      return false if not @$store.getters['application/is_agency']
      @$store.state.orders.current?.shipping_status in ['collected', 'delivered', 'cancelled', 'abandoned']

    pay_url: ->
      "/orders/#{@order.id}/pay"

    payment_status: ->
      {
        'pending_payment': @$t('order_payment_status_pending_payment'),
        'underpaid': @$t('order_payment_status_underpaid'),
        'overpaid': @$t('order_payment_status_overpaid'),
        'paid': @$t('order_payment_status_paid')
      }[@order.status]

    actionable_item_ids: ->
      @order_items
        .filter (item) -> (item.qty != item.qty_ordered) && !item.modification
        .map (item) -> item.id

    total_savings_in_cents: ->
      savings_in_cents = 0
      for item in @order_items.filter((item) -> item.base_price_in_cents?)
        savings_in_cents += @item_savings_in_cents(item) * item.qty
      savings_in_cents

    invoice_url: -> @order?.pdf_invoice_url
  }

  methods: {
    payment_text: ->
      map = {
        'PAYGATE': @$t('order_page_payment_method_paygate')
        'CASH': @order.requires_shipping && @$t('checkout_confirm_payment_option_cash_deliver') || @$t('checkout_confirm_payment_option_cash_collect')
        'DEBTORCARD': @$t('order_page_payment_method_debtorcard')
        'SHOP2SHOP': 'Shop2Shop'
        'MOYA': 'MoyaPay'
        'YOCO': 'Paid Online (Card or EFT)'
      }
      map[@order.provider.toUpperCase()]

    formatted_datetime: (string, format) ->
      formatted_datetime(string, format)

    formatted_order_item_price: (order_item) ->
      text = "#{@$store.getters['application/currency']} #{order_item.price}"
      if order_item.per_kg then "#{text} /#{order_item.per_kg_symbol}" else text

    formatted_order_item_qty: (item) ->
      if item.per_kg
        qty = Number(item.qty) / Number(item.per_kg_conversion_rate)
        formatted_qty = if item.per_kg_symbol.toLowerCase() in ['kg', 'l'] then qty.toFixed(3) else qty.toFixed(0)
        "#{formatted_qty}#{item.per_kg_symbol}"
      else
        item.qty

    build_tab_show: (shipping_status) ->
      if shipping_status in ['collected', 'delivered', 'stock_short', 'pending_payment', 'abandoned', 'cancelled']
        @tab = 'receipt'
      else
        @tab = 'timeline'
      @scroll_to_reorder() if shipping_status in ['collected', 'delivered']

    scroll_to_reorder: ->
      @scroll_to_bottom_timeout = setTimeout =>
        @$vuetify.goTo(@$refs['reorder-button'], { offset: 8, container: document.documentElement })
      , 500

    cancel_order_prompt: ->
      @confirm_cancel_order_modal = true

    cancel_order: (confirmed) ->
      @$store.dispatch('orders/cancel_current') if confirmed
      @confirm_cancel_order_modal = false

    formatted_text: (string) ->
      String(string).split('_').join(' ').toUpperCase()

    modification_icon: (modification) ->
      return 'fas fa-check' if modification.action == 'accept'
      return 'fas fa-times' if modification.action == 'remove'

    new_modification: (action, order_item_id) ->
      @$store.commit('orders/new_modification', { action: action, order_item_id: order_item_id })
      @focus_next_action()

    focus_next_action: ->
      id = @actionable_item_ids[0]
      if id
        @scroll_to(@$refs["order-item-#{id}"][0])
      else
        @scroll_to(@$refs['bottom-controls'])

    new_order_item: ->
      @$store.dispatch('suppliers/fetch')
        .then => @$store.dispatch('suppliers/select', @order.supplier_id)
          .then =>
            @$store.commit('cart/start_order_proxy', @order.id)
            @$router.push('/')

    number_to_currency: (cents) ->
      number_to_currency({ symbol: @$store.getters['application/currency'], cents: cents })

    update_order: ->
      @$store.dispatch('suppliers/fetch')
        .then =>
          @$store.dispatch('suppliers/select', @order.supplier_id)
            .then =>
              @$store.dispatch('orders/validate_stock_short_items').then (valid) =>
                if valid
                  @$store.dispatch('orders/validate_modifications').then (valid) =>
                    if valid
                      @$store.dispatch('orders/modify')
                    else
                      @remote_validation_modal = true
                  .catch (error) ->
                    console.error 'orders/validate_new_items:catch', error
                else
                  id = @invalid_order_item
                  @scroll_to(@$refs["order-item-#{id}"][0]) if !!id
              .catch (r) ->
                console.error 'orders/validate_current:catch', r

    scroll_to: (el) ->
      @$vuetify.goTo(el, { offset: 8, container: document.documentElement })

    show_topup_detail: ->
      @$store.dispatch('suppliers/fetch')
        .then =>
          @$store.commit('layout/show_debtorcard_topup_detail', {
            supplier: @$store.getters['suppliers/find'](@supplier_id)
            card: @$store.getters['account/find_debtorcard'](@supplier_id)
          })

    resolve_payment_pending: ->
      @$store.dispatch('orders/resolve_payment_pending', @order.id)
      .then (response) ->
        # console.log 'order_page.resolve_payment_pending.then'
      .catch (error) ->
        # console.error "order_page: #{error}"
      .finally (xhr) ->
        # console.log 'order_page.resolve_payment_pending.finally'

    initiate_reorder: ->
      @loading_reorder = true
      @$store.dispatch('orders/reorder')
      .then =>
        @$router.push('/basket')
      .catch (error) ->
        @loading_reorder = false
        console.error error

    has_provider: (provider) ->
      @$store.getters['orders/has_provider'](provider)

    formatted_item_savings: (item) ->
      savings_in_cents = @item_savings_in_cents(item)
      return @number_to_currency(savings_in_cents * item.qty) if savings_in_cents > 0
      null

    item_savings_in_cents: (item) ->
      return null unless item.base_price_in_cents?
      cost_at_base_price = item.base_price_in_cents * Number(item.sellable.pack)
      cost_at_base_price - item.unit_price_in_cents
  }
}
